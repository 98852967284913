import { createSlice } from '@reduxjs/toolkit'

export const menuSlice = createSlice({
  name: 'menuVisible',
  initialState: {
    value: false,
  },
  reducers: {
    toggle: (state) => {
      state.value = !state.value
    },
    setVisible: (state) => {
      state.value = true;
    },
    setMenuInvisible: (state, action) => {
      state.value = false;
    },
  },
})

// Action creators are generated for each case reducer function
export const { toggle, setVisible, setMenuInvisible } = menuSlice.actions

export default menuSlice.reducer