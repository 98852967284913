import React from 'react';
import { motion } from 'framer-motion';

import Catalog from '../../components/catalog/catalog.jsx';

const Work = ({projects}) => {
  return (
    <motion.div 
      initial={{ 
        opacity: 0,
        y:10 
      }}
      animate={{ 
        opacity: 1,
        y:0 
      }}
      exit={{ 
        opacity: 0,
        y:-10 
      }}
      transition={{ duration: 0.3 }}  
    >
      <Catalog projects={projects} />
    </motion.div>

  );
}

export default Work;