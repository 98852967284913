import React from 'react';
import './catalog-tile.css';
import {Link} from 'react-router-dom';


const CatalogTile = ({project}) => {
  return (
    <>
      <Link to={`/project/${project.id}`} style={{ textDecoration: 'none' }}>
        <div className="work-tile">
            <div className="work-tile-img" 
              style={{ 
                backgroundImage: `url('/images/catalog_tiles/${project.catalog_tile_image}')` 
              }}
            >
              <div className="work-tile-screen">
                <span className="work-tile-label" >{project.name}</span>
              </div>
            </div>
        </div>
      </Link>
    </>
  )
}

export default CatalogTile;
