import React from 'react';
import { Grid, Container } from'@mui/material';
import SocialBlock from '../../components/social-block/social-block'
import './info.css';
import { motion } from 'framer-motion';


const Info = () => {
    const STAGGER = 0.07;

    return (
      <motion.div 
        className='info-container'
        initial={{ 
          opacity: 0,
          y:10 
        }}
        animate={{ 
          opacity: 1,
          y:0 
        }}
        exit={{ 
          opacity: 0,
          y:-10 
        }}
        transition={{ duration: 0.3 }}  
      >
        <Container >
          <Grid container 
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              marginTop="20vh"
            >
              <Grid item xs={12} sm={12} md={12}
                alignItems="center"
                justifyContent="center"
              >
                <div className='hero-block'>
                  <motion.div
                    initial={{ opacity: 0,y:10 }}
                    animate={{ opacity: 1 , y:0 }}
                    transition={{ duration: 0.4, delay: 1 * STAGGER }}
                  >
                    <img className='hero-portrait' src='images/2a5cd8ccea57ab6f216aaee4f4848698.jpg' alt='Chris McGee' />
                  </motion.div>
                  <div className='hero-text'>
                    <motion.div
                      initial={{ opacity: 0,y:10 }}
                      animate={{ opacity: 1 , y:0 }}
                      transition={{ duration: 0.4, delay: 2 * STAGGER }}
                    >
                      <h2>Hi, I'm Chris</h2>
                    </motion.div>
                    <motion.div
                      initial={{ opacity: 0,y:10 }}
                      animate={{ opacity: 1 , y:0 }}
                      transition={{ duration: 0.4, delay: 3 * STAGGER }}
                    >
                      <p>Currently I am an  Engineering Manager at <a className="std" href='https://penumbra.com' target='new'>Penumbra</a>, working with the Platform Team on the <a className="std" href='https://realsystem.com' target='new'>REAL® System</a>, which is a virtual reality rehabilitation tool that allows providers, such as physical therapists, occupational therapists, and speech language pathologists, to customize therapy sessions for high-acuity to high-functioning patients. This increases retention, and allows us to track their improvement precisely over time.</p>
                      <p>Prior to Penumbra, I wore a combination of hats that could best be described as Lead Game Developer / UX Director / Full-Stack Engineer for <a className="std" href='https://floathybrid.com' target='new'>Float Hybrid Entertainment</a>; designing and developing experiences, apps, games, and software systems for some of the worlds largest brands. A large part of my work there was centered around developing experiences/demos for prototype hardware that was yet to reach market, or working together with hardware developers to bring their products to a shippable level. This was the most interesting stuff.</p>
                      <p>Since the onset of COVID-19, I also helped launch <a className="std" href='https://songaid.com' target='new'>SongAid™</a> which is a platform for generating revenue for social causes through streaming music from online providers. SongAid™ is a <a className="std" href='https://tracktribe.com' target='new'>TrackTribe™</a> project.</p>
                      <p>Prior to Float, I spent a little over 16 years designing and developing games in the AAA Console space for <a className="std" href='https://www.starwars.com/games-apps' target='new'>LucasArts</a>, <a className="std" href='https://www.ea.com/' target='new'>Electronic Arts</a>, <a className="std" href='http://doublefine.com/' target='new'>Doublefine</a> and others. This has given me a lot of insight into overall experience design, but also the gamification of elements within non-game contexts and systems.</p>
                      <p>When not developing experiences and software for clients, or chasing my two young children around, or playing Squash, I play music in the San Francisco bay area with several musical projects, most notably <a className="info" href='http://jazzmafia.com' target='new'>The Jazz Mafia</a>.</p>
                      <SocialBlock />
                    </motion.div>
                  </div>
                </div>
              </Grid>
            </Grid>
        </Container>
      </motion.div>
    )
}

export default Info;