export const projectData =  [
        {  
                "id":23,
                "name":"REAL® System",
                "youtube_url" : "iIITG1E5RWo",
                "description" : "<p><a className='std' href='https://www.realsystem.com/'>REAL® System</a> is a virtual reality (VR) rehabilitation tool that allows providers, such as physical therapists, occupational therapists, and speech language pathologists, to customize therapy sessions for high-acuity to high-functioning patients.</p><p>REAL System is developed by <a className='std' href='https://www.penumbra.com/'>Penumbra Inc.</a></p><p>Role: <b>Engineering Manager</b></p>",
                "additional_assets":[],
                "catalog_tile_image":"real0.png",
                "tags":["work","experiences"]
        },
        {  
                "id":22,
                "name":"SONGAID™",
                "description" : "<p><a className='std' href='https://songaid.com'>SongAid™</a> is a platform where the simple act of listening to music, engaging in dialogue and sharing on social media helps in the fight for equal rights and justice. Funds generated from SongAid content go to organizations investing in the foundations of equality: ending hunger and poverty, creating educational and economic opportunities, criminal justice reform, improving health and well-being, and environmental preservation.</p><p>Role: <b>Advisor / Developer</b></p>",
                "hero_tile_asset" : "songaid0.jpg",
                "additional_assets":[],
                "catalog_tile_image":"songaid1.png",
                "tags":["work","experiences"]
        },
        {  
                "id":21,
                "name":"TRACKTRIBE™",
                "description" : "<p><a href='https://tracktribe.com'>TrackTribe</a> is a collective of artists, musicians and technology professionals. We provide creative services and high-quality music for creators, media companies and fans. We are committed to making a positive global impact by empowering creative communities and supporting social causes. Our tracks have sonically powered millions of videos on youtube and various other platforms, and have been streamed and downloaded billions of times.</p><p>Role: <b>Advisor / Developer</b></p>",
                "hero_tile_asset" : "tracktribe0.jpg",
                "additional_assets":[],
                "catalog_tile_image":"tracktribe.png",
                "tags":["work","experiences"]
        },
        {  
                "id":20,
                "name":"COPA STC",
                "youtube_url" : "bRLuDuViPiw",
                "description" : "<p><a href='https://copastc.com'>CopaSTC</a> is a next generation soccer training facility focused on improving player performance through the use of data in conjunction with a staff of world class trainers, and cutting edge devices. Curriculum designed and led by ex-pro soccer players, and coaches. Activities span basic soccer technique, agility and cognitive training, as well as speed and strength training.</p><p>Role: <b>Engineering Manager / Lead Developer</b></p>",
                "additional_assets":[],
                "catalog_tile_image":"copa.png",
                "tags":["work","experiences"]
        },
        {  
                "id":19,
                "name":"SAMSUNG QLED TV",
                "youtube_url" : "TG_5oY2T98Y",
                "description":"<p>To promote the launch of their QLED TV line, <a href='https://www.samsung.com/us/'>Samsung</a> in partnership with <a href='https://www.encoreww.com/'>Encore Worldwide</a> created a multi-room, expandable, doublewide semi trailer installation that traveled the country showcasing the capabilities of this best in class TV.</p><p>In it's first phase, the installation contained a projection mapped room, and a series of XBOX One enabled racing sleds where users competed in Forza Horizon for a chance to win a QLED TV.</p><p>We created the projection mapping experience, and the leaderboard / registration system for the racers. In the second phase, we replaced the racing sleds with 3 Kinect-driven football receiver games. This was then taken around the college football tailgate circuit.</p><p>Role: <b>Development Director / Experience Designer</b></p>",
                "additional_assets":["samsung0.png","samsung1.png","samsung3.png","samsung4.png","samsung6.png"],
                "catalog_tile_image":"samsung.png",
                "tags":["work","experiences"]
        },
        {  
                "id":18,
                "name":"SHOPPERTRACK",
                "youtube_url" : "mkEiyiOVF2Y",
                "description":"<p><a className='std' href='https://www.sensormatic.com/shoppertrak-retail-traffic-insights'>Shoppertrack</a> Retail Lab set about to create a suite of experiences to innovate the customer experience in the physical retail space. </p><p>Among several projects within this group was the Interactive Shoe Wall; a video display enabled grid of shoes, which ran ambient video content behind the entire display.</p><p>Interactions with each shoe were tracked in real time, and relevant video content was shown behind any shoe that customers interacted with.</p><p>Role: <b>Developer</b></p>",
                "additional_assets":["shoppertrack0.jpg","shoppertrack5.jpg","shoppertrack1.jpg","shoppertrack2.jpg","shoppertrack3.jpg"],
                "catalog_tile_image":"shoppertrack1.png",
                "tags":["work","experiences"]
        }, 
        {  
                "id":17,
                "name":"BUD LIGHT VIRTUAL FITTING ROOM",
                "youtube_url" : "jWmwVOIIcRM",
                "description":"<p>Virtual fitting room for various Bud Light related events and shows comissioned by <a href='https://www.anheuser-busch.com/'>Anheuser Busch</a>.</p><p>Users selected swag, which was then virtually overlaid on their bodies on a video wall. Swag could be purchased or given away as prizes. Photos of virtually overlaid users were emailed to them procedurally by the installation.</p><p>Role: <b>Developer</b></p>",
                "additional_assets":["budlight_fittingroom0.png","budlight_fittingroom1.png","budlight_fittingroom2.png","budlight_fittingroom3.png"],
                "catalog_tile_image":"budlight_fittingroom.png",
                "tags":["work","experiences"]
        },
        {  
                "id":16,
                "name":"BEN HUR",
                "youtube_url" : "3BmeR9GYdDU",
                "description":"<p>To accompany the 2016 release of the Ben Hur movie, <a className='std' href='https://www.warnerbros.com/'>Warner Brothers</a> commisioned us to create a free downloadable, single player XBOX 360 game based on the chariot racing sequence in the movie.</p><p>Players raced as Ben Hur against several AI opponents in a series of 3 races of increasing difficulty. Picture-in-picture cam captured slow motion destruction of chariots in collisions around you.</p><p>Role: <b>Lead Designer / Developer</b></p>",
                "additional_assets":["benhur5.jpg","benhur1.jpg","benhur2.jpg","benhur3.jpg","benhur4.jpg"],
                "catalog_tile_image":"benhur.png",
                "tags":["work","games"]
        },
        {  
                "id":15,
                "name":"THE JORDAN STANDARD",
                "youtube_url" : "vzlu8y_eKXo",
                "description":"<p>In concert with the NBA All-Star Weekend in 2016, Nike launched a Jordan store in Toronto, Canada. Among the many special features within the store was The Jordan Standard; a basketball training experience meant to challenge players, and measure their performance in 5 key aspects against the legend himself.</p><p>The experience was displayed on a massive LED wall, and driven by 3 sensors (2 Kinects, 1 bluetooth basketball) to provide real time performance stats, and dynamic difficulty ramping through 5 different skill-based drills.</p><p>Role: <b>Lead Designer / Lead Developer</b></p>",
                "additional_assets":["jordan6.jpg","jordan2.jpg","jordan3.jpg","jordan4.jpg","jordan1.jpg"],
                "catalog_tile_image":"jordan.png",
                "tags":["work","games","experiences"]
        },
        {  
                "id":14,
                "name":"DAQRI SMART HELMET",
                "youtube_url" : "OVNDi1aHJiw",
                "description":"<p>For CES 2016, Daqri announced their Augmented Reality enabled Helmet for the future of work by way of a real time demo.</p><p>The demo featured a series of activities showcasing the helmet's abilities to communicate information to it's wearer on an AR layer, based on physical objects around them.</p><p>The helmet contained a full suite of capabilities including gyroscopic menu navigation, wifi, infrared camera, and voice control.</p><p>Role: <b>Lead Designer / Developer</b></p>",
                "additional_assets":["daqri1.jpg","daqri2.jpg","daqri3.jpg","daqri4.jpg","daqri5.jpg"],
                "catalog_tile_image":"daqri.png",
                "tags":["work","experiences"]
        },
        {  
                "id":13,
                "name":"PEPSI SXSW2015",
                "youtube_url" : "NUwtRM7hNTE",
                "description":"<p>Interactive vending machine experience built for <a className='std' href='https://www.pepsico.com/'>PepsiCo</a>'s #FutureOfTheFest campaign at South by Southwest 2015.</p><p>Customers interacted with a futuristic virtual fortune telling android. Kinect tracked the position of the user, and enabled the fortune teller to look at and gesture to users accurately. Touchscreen enabled drink selection. Event specific fortunes were given to event attendees at the end of each interaction.</p><p>Role: <b>Lead Designer / Lead Developer</b></p>",
                "additional_assets":[
                "psxsw2015_0.jpg",
                "psxsw2015_1.jpg"],
                "catalog_tile_image":"PSXSW2015.png",
                "tags":["work","experiences"]
        },  
        {  
                "id":12,
                "name":"TMNT:TRAINING LAIR",
                "youtube_url" : "VZZ0PnDZdZk",
                "description":"<p>In 2014 <a className='std' href='https://www.paramountmovies.com/'> Paramount</a> in conjunction with Michael Bay rebooted the  Teenage Mutant Ninja Turtles franchise with a movie. To increase buzz around the movie, we created a Kinect-driven XBOX 360 game to showcase the weapons of the turtles.</p><p>Player is composited into the 3D world via the kinect's rgb camera, and various weapons are placed in the player's hands. Each weapon has its own collision shape. Psi were throwable.</p><p>Role: <b>Lead Designer / Developer</b></p>",
                "additional_assets":["tmnt1.jpg","tmnt2.jpg","tmnt3.jpg","tmnt4.jpg","tmnt5.jpg"],
                "catalog_tile_image":"tmnt.png",
                "tags":["work","games","experiences"]
        },
        {  
                "id":11,
                "name":"PEPSI FOOTBALL 2014",
                "youtube_url": "yfuh7f1PBpY",
                "description":"<p>Kinect Driven DDR style soccer juggling game for Pepsi's Football Worldwide Live For Now campaign during the 2014 World Cup.</p><p>Players pose-matched against an animated character juggling a soccer ball. Earning the minimum point threshold won a free pepsi for the player.</p><p>The game was installed on 40+ vending machines throughout Europe, The Middle East, South Africa, and The United States.</p><p>Role: <b>Lead Designer / Principle Developer</b></p>",
                "additional_assets":["pf2014_3.jpg","pf2014_4.jpg","pf2014_2.jpg"],
                "catalog_tile_image":"pepsi_worldcup.png",
                "tags":["work","games","experiences"]
        }, 
        {  
                "id":10,
                "name":"STAR WARS:ULTIMATE SITH EDITION",
                "hero_tile_asset":"ultSith0.jpg",
                "description":"<p>A follow up to the Force Unleased II, this was a DLC pack containing some original content, as well as revisits to worlds seen in previous TFU editions.</p><p>Return to Kashyk as Vader. Rampage on Bespin as the Sith Lord version of Starkiller. Additional fan favorite gems such as a boss fight against Darth Maul were also in this release.</p><p>Available on XBOX 360 and PS3.</p><p>Role: <b>Senior Designer</b></p>",
                "additional_assets":["ultSith1.jpg","ultSith2.jpg","ultSith3.jpg","ultSith4.jpg"],
                "catalog_tile_image":"ultimate_sith.png",
                "tags":["work","games"]
        },
        {  
                "id":9,
                "name":"STAR WARS:THE FORCE UNLEASHED II",
                "hero_tile_asset":"tfu2_1.jpg",
                "description":"<p>3rd person action adventure for XBOX 360, and PS3. Developed and published by Lucasarts in 2010.</p><p>Continue the adventures of Starkiller, Vader's secret apprentice, now free from the constraints of his old master.</p><p>Featured upgraded force moves, improved light saber combat, and additional locations.</p><p>Role: <b>Senior Designer</b></p>",
                "additional_assets":["tfu2_2.jpg","tfu2_3.jpg","tfu2_4.jpg"],
                "catalog_tile_image":"tfu2.png",
                "tags":["work","games"]
        },
        {  
                "id":8,
                "name":"DANTE'S INFERNO",
                "hero_tile_asset":"",
                "youtube_url":"-35gTPPBRUU",
                "description":"<p>3rd person action adventure take on Virgil's classic book of the same name. Developed and published by Electronic Arts for XBOX 360, and PS3 in 2010.</p><p>Though I was primarily responsible for the 5th circle of Hell (Anger), I did additional scripting and combat tuning in several other areas throughout the game including the King Midas boss fight, Crossing the River Styx on Charon, and the decent into the final boss fight.</p><p>Role: <b>Senior Designer</b></p>",
                "additional_assets":["dantes1.jpg","dantes3.jpg","dantes5.jpg","dantes6.jpg"],
                "catalog_tile_image":"dantes.png",
                "tags":["work","games"]
        },
        {  
                "id":7,
                "name":"STAR WARS:THE FORCE UNLEASHED",
                "hero_tile_asset":"tfu1_0.jpg",
                "description":"<p>3rd person action adventure based on the adventures of Starkiller - Darth Vader's secret apprentice. Set between Episode III and Episode IV, this marks the birth of the the rebellion.</p><p>Developed and published by <a href='https://www.starwars.com/games-apps'>Lucasarts</a> for XBOX 360, and PS3 in 2010.</p><p>Level design/layout, and all combat/object interactivity scripting for Raxus Prime, plus additional scripting on various levels throughout the game.</p><p>Role: <b>Senior Designer</b></p>",
                "additional_assets":["tfu1_1.jpg","tfu1_2.jpg","tfu1_3.jpg","tfu1_4.jpg","tfu1_5.jpg","tfu1_6.jpg","tfu1_7.jpg"],
                "catalog_tile_image":"tfu1.png",
                "tags":["work","games"]
        },
        {  
                "id":6,
                "name":"CONAN",
                "hero_tile_asset":"conan2.jpg",
                "description":"<p>3rd person action adventure set in the fictional world of Howard/Frizetta. Developed by Nihilistic, published by THQ for XBOX 360 and PS3 in 2005.</p><p>Level design, layout and combat scripting all throughout the game.</p><p>Role: <b>Lead Level Designer</b></p>",
                "additional_assets":["conan1.jpg","conan3.jpg","conan4.jpg","conan5.jpg"],
                "catalog_tile_image":"conan.png",
                "tags":["work","games"]
        },
        {  
                "id":5,
                "name":"MARVEL:NEMESIS",
                "hero_tile_asset":"nemesis4.jpg",
                "description":"<p>3rd person arena combat game in the Marvel universe. Developed by Nihilistic Software, published by <a href='https://www.ea.com/'>Electronic Arts</a> in 2005. Level design / layout, combat and boss fight scripting for several areas throughout the game.</p><p>Role: <b>Level Designer</b></p>",
                "additional_assets":["nemesis3.jpg","nemesis1.jpg","nemesis2.jpg"],
                "catalog_tile_image":"nemesis.png",
                "tags":["work","games"]
        },
        {  
                "id":4,
                "name":"STARCRAFT:GHOST",
                "hero_tile_asset":"ghost0.jpg",
                "description":"<p>3rd person action adventure based on the iconic character that first appeared in the Starcraft RTS. Developed by Nihilistic Software and <a href='https://www.blizzard.com/'>Blizzard Entertainment</a> for XBOX and PS2.</p><p>Penetrate enemy territory undetected, take out enemies and objectives from the shroud of Nova's cloaked suit.Level design and combat / puzzle scripting throughout various areas within the game.</p><p>This was the greatest game to have never shipped.</p><p>Role: <b>Level Designer</b></p>",
                "additional_assets":["ghost8.jpg","ghost2.jpg","ghost3.jpg","ghost4.jpg","ghost7.jpg","ghost1.jpg"],
                "catalog_tile_image":"ghost.png",
                "tags":["work","games"]
        },
        {  
                "id":3,
                "name":"PSYCHONAUTS",
                "youtube_url" : "aDbDShqvc_o",
                "description":"<p>3rd person action adventure for XBOX. Developed by <a href='https://www.doublefine.com/'>Doublefine</a>, published by Majesco in 2005. Level design, interaction, load control, and puzzle scripting throughout Campgrounds, Lungfishopolis , Milkman Conspiracy, Gloria's Theatre, and the Asylum Upper Floors.</p><p>Role: <b>Level Designer</b></p>",
                "additional_assets":["psychonauts0.jpg","psychonauts1.jpg","psychonauts2.jpg","psychonauts3.jpg","psychonauts4.jpg"],
                "catalog_tile_image":"psychonauts.png",
                "tags":["work","games"]
        },
        {  
                "id":2,
                "name":"INDIANA JONES AND THE INFERNAL MACHINE",
                "hero_tile_asset":"indy1.jpg",
                "description":"<p>Level Design on 3rd person action adventure game for PC and N64. Published by <a href='https://www.starwars.com/games-apps'>Lucasarts</a> 1999.</p><p>Worked primarily on Canyonlands, Shambala Waterworks area, and Palawan Volcano Temple. Responsible for level layout, combat and puzzle scripting, texturing and lighting.</p><p>Role: <b>Level Designer</b></p>",
                "additional_assets":["indy2.jpg","indy3.jpg","indy4.jpg","indy5.jpg"],
                "catalog_tile_image":"jones.png",
                "tags":["work","games"]
        },
        {  
                "id":1,
                "name":"JEDI KNIGHT:MYSTERIES OF THE SITH",
                "hero_tile_asset":"JK_MOTS.jpg",
                "description":"<p>3rd person action adventure for PC. Published by <a href='https://www.starwars.com/games-apps'>Lucasarts</a> 1998. Design, modeling, lighting and scripting on Karobanis Base, and the Reactor Core Multiplayer level. </p><p>Role: <b>Level Designer</b></p>",
                "additional_assets":[],
                "catalog_tile_image":"sith.png",
                "tags":["work","games"]
        }
]
