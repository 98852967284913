import React from 'react'
import './social-block.css'


const SocialBlock = () => {

    return (
        <div className='social-block'>
            <a href='https://www.linkedin.com/pub/chris-mcgee/1/377/8b6' target='new'>
                <img className='social-icon' src='images/social/li.png' alt='' />
            </a>
            <a href='https://www.facebook.com/chris.mcgee.71' target='new'>
                <img className='social-icon' src='images/social/fb.png' alt='' />
            </a>
            <a href='http://chris-mcgee.com/chris_mcgee_resume.pdf' target='new'>
                <img className='social-icon' src='images/social/res.png' alt='' />
            </a>
            <a href='mailto:cmg@chris-mcgee.com' target='new'>
                <img className='social-icon' src='images/social/mail.png' alt='' />
            </a>
        </div>
    )
}

export default SocialBlock