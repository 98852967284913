import React, { useState, useEffect, createRef }  from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './collage.css';

import PartnerLogo from '../partner-logo/partner-logo'

// const animationDuration = { enter: 1000, exit: 500 }
const animationStagger = 0.1 



const Collage = () => {

    // return the number of logos that should
    // be shown based on screen width
    function getPartnerCount() {
        let target_partner_count
        if (window.innerWidth >= 1100) {
            target_partner_count = 6
        } else if (window.innerWidth >= 900 && window.innerWidth < 1100) {
            target_partner_count = 5
        } else if (window.innerWidth >= 600 && window.innerWidth < 900) {
            target_partner_count = 4
        } else if (window.innerWidth >= 450 && window.innerWidth < 600) {
            target_partner_count = 3
        } else if (window.innerWidth >= 260 && window.innerWidth < 450) {
            target_partner_count = 2
        } else if (window.innerWidth < 260) {
            target_partner_count = 1
        }
        return target_partner_count
    }
    

    // list of partner logos
    const partners = [
        {
            id: 0,
            logo: 'nike.png',
            class : 'collage-item-lg',
            nodeRef: createRef(null)
        },
        {
            id: 1,
            logo: 'ea.png',
            class : 'collage-item-lg',
            nodeRef: createRef(null)
        },
        {
            id: 2,
            logo: 'paramount.png',
            class : 'collage-item-lg',
            nodeRef: createRef(null)
        },
        {
            id: 3,
            logo: 'copa.png',
            class : 'collage-item',
            nodeRef: createRef(null)
        },
        {
            id: 4,
            logo: 'pepsi.png',
            class : 'collage-item',
            nodeRef: createRef(null)
        },
        {
            id: 5,
            logo: 'akqa.png',
            class : 'collage-item-lg',
            nodeRef: createRef(null)
        },
        {
            id: 6,
            logo: 'tracktribe.png',
            class : 'collage-item-lg',
            nodeRef: createRef(null)
        },
        {
            id: 7,
            logo: 'ms.png',
            class : 'collage-item',
            nodeRef: createRef(null)
        },
        {
            id: 8,
            logo: 'lucasarts.png',
            class : 'collage-item-lg',
            nodeRef: createRef(null)
        },
        {
            id: 9,
            logo: 'lux.png',
            class : 'collage-item',
            nodeRef: createRef(null)
        },
        {
            id: 10,
            logo: 'doublefine.png',
            class : 'collage-item-lg',
            nodeRef: createRef(null)
        },
        {
            id: 11,
            logo: 'songaid.png',
            class : 'collage-item-lg',
            nodeRef: createRef(null)
        },
        {
            id: 12,
            logo: 'daqri.png',
            class : 'collage-item',
            nodeRef: createRef(null)
        },
        {
            id: 13,
            logo: 'samsung.png',
            class : 'collage-item',
            nodeRef: createRef(null)
        }
    ]
   

    // partner logo shuffle state controls
    const [curPartnerSet, setPartnerList] = useState(partners.slice(0,getPartnerCount()))
    let curIdx = getPartnerCount()
    const [curPartnerIndex, setPartnerIndex] = useState(curIdx)


    // update partners with next set of logos
    // based on curPartnerIndex and screen width
    const calculatePartnerSet = () => {
       
        let newPartnerSet = []
        let newPartnerIndex
        let target_partner_count = getPartnerCount()

        if (curPartnerIndex === partners.length ) {
            newPartnerSet = partners.slice(0,target_partner_count)
            newPartnerIndex = target_partner_count
        } else if ((curPartnerIndex + target_partner_count) > partners.length) {
            let initialSelection = partners.slice(curPartnerIndex)
            let remainder = partners.slice(0,(target_partner_count - initialSelection.length))
            newPartnerIndex = remainder.length
            newPartnerSet = initialSelection.concat(remainder)
        } else {
            newPartnerSet = partners.slice(curPartnerIndex,(curPartnerIndex+target_partner_count))
            newPartnerIndex = curPartnerIndex + target_partner_count
        }

        // start animation transition
        setPartnerIndex(newPartnerIndex)
        setPartnerList([...newPartnerSet])

    }

    
    useEffect(() => {
        // reshuffle interval
        const shuffle_timer = setInterval(() => {
            calculatePartnerSet()
        }, 5000);
        
        return () => {
            clearInterval(shuffle_timer);
        }
    }, [curPartnerIndex]);

    
    return (
        <div className='collage-outer-container'>
            <div className='label'>Partners:</div>
            <div className='collage-container'>
                <AnimatePresence mode="wait">
                    {curPartnerSet.map((el, idx) => (
                        <motion.div
                            key={el.id}
                            initial={{ opacity: 0, x:20 }}
                            animate={{ opacity: 1, x:0 }}
                            exit={{ opacity: 0 , x:-20}}
                            transition={{ duration: 0.4, delay:idx * animationStagger, type: 'spring', stiffness: 100 }}
                        >
                            <PartnerLogo partner={el} />
                        </motion.div>
                    ))}
                </AnimatePresence>
            </div>

        </div>       
    );
}

export default Collage;