import React from 'react';
import {
    NavLink
} from "react-router-dom";
import './global-nav.css';

import { useSelector, useDispatch } from 'react-redux';
import { toggle, setMenuInvisible } from '../../features/menu/menuSlice';

import Burger from '@animated-burgers/burger-slip';
import './burger-styles.scss';
import './burger-slip-styles.scss';


const GlobalNav = () => {
    const menuVisible = useSelector((state) => state.menu.value);
    const dispatch = useDispatch()

    const toggleMenu = () => {
        dispatch(toggle());
    }


    return(
        <div className='header-container'>
            <NavLink 
                className ='title nav-link' 
                to='/'
                onClick={() => dispatch(setMenuInvisible())}
            >
                Chris McGee
            </NavLink>
            <div className='nav-container'>
                <NavLink 
                    className ='nav-link nav-button' 
                    to='/'
                    style={({ isActive }) => ({
                        color: isActive ? '#80dcff' : '',
                        pointerEvents: isActive ? 'none' : 'auto'
                    })}
                >
                    HOME
                </NavLink>
                <NavLink 
                    className ='nav-link nav-button' 
                    to='/work'
                    style={({ isActive }) => ({
                        color: isActive ? '#80dcff' : '',
                        pointerEvents: isActive ? 'none' : 'auto'
                    })}
                >
                    WORK
                </NavLink>
                <NavLink 
                    className ='nav-link nav-button' 
                    to='/info'
                    style={({ isActive }) => ({
                        color: isActive ? '#80dcff' : '',
                        pointerEvents: isActive ? 'none' : 'auto'
                    })}
                >
                    INFO
                </NavLink>
            </div>
            <div className='nav-mini'>
                <Burger
                    type='button'
                    className='slip'
                    isOpen={menuVisible}
                    direction='right'
                    onClick={() => toggleMenu()}
                />
            </div>
        </div>
    )
}

export default GlobalNav;
