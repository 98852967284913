import React from 'react'
import './partner-logo.css';

const PartnerLogo = ({partner}) => {
    return(
        <div>
            <img src={`images/company_logos/greyscale/${partner.logo}`} className={partner.class} alt=''/>
        </div>
    )
}

export default PartnerLogo