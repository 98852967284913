
import React from 'react'
import { Grid, Container } from'@mui/material'
import { motion } from 'framer-motion';


import Collage from '../../components/collage/collage'
import SocialBlock from '../../components/social-block/social-block'
import './home.css'

const Home = () => {

    return (
      <motion.div 
        className='home-container'
        initial={{ 
          opacity: 0,
          y:10 
        }}
        animate={{ 
          opacity: 1,
          y:0 
        }}
        exit={{ 
          opacity: 0,
          y:-10 
        }}
        transition={{ duration: 0.3 }}
      >
        <Container sx={{mt: 6}}>
            <Grid container 
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{ minHeight: '60vh' }}
            >
              <Grid item xs={12} sm={12} md={12}
                alignItems="center"
                justifyContent="center"
              >
                <div className='center-block'>
                  <img className='portrait' src='images/anime2.png' alt='' />
                  <div className='center-text'>
                    Software Developer and Manager in San Francisco.<br />
                    Interactive Installations, Games, VR, Music.<br />
                    Let's make some stuff.<br />
                    <SocialBlock />
                  </div>
                </div>
              </Grid>
            </Grid>
            <Collage></Collage>
         </Container> 
        </motion.div>
    );
}

export default Home;