import './App.css';
import {
  BrowserRouter,
  // Routes,
  // Route,
  // useLocation 
} from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import store from './store';
import { Provider } from 'react-redux';
// import { motion, AnimatePresence } from 'framer-motion';

import GlobalNav from './components/global-nav/global-nav'
import MobileMenu from './components/mobile-menu/mobile-menu'
// import Home from './routes/home/home';
// import Work from './routes/work/work';
// import Info from './routes/info/info';
// import Project from './routes/project/project';
// import { projectData } from "./data/config";
import AnimatedRoutes from './routes/animated-routes';

const theme = createTheme({
  components: {
    // Name of the component
    MuiIcon: {
      styleOverrides: {
        root: {
          color: '#292929',
          fontSize: 'large',
          cursor: 'pointer',
          '&:hover' : {
            color:'80dcff'
          }
        }
      },
    }
  },
});

function App() {

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <div className="App">
            
            <GlobalNav />
            <AnimatedRoutes theme={theme} />
            <MobileMenu />

          </div>
        </Provider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
