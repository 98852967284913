import React from 'react';
import { motion } from 'framer-motion';
import CatalogTile from '../catalog-tile/catalog-tile';
import './catalog.css';

const Catalog = ({projects}) => {
    const STAGGER = 0.07;
    return (
        <div className='catalog-container'>
            {projects.map((p, idx) => (
              <motion.div
                key={p.id}
                initial={{ opacity: 0,y:10 }}
                animate={{ opacity: 1 , y:0 }}
                transition={{ duration: 0.4, delay: idx * STAGGER }}
              >
                <CatalogTile project={p} />
              </motion.div>
            ))}
        </div>
    )
   
}

export default Catalog;